var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Tätigkeiten")])])],1),(_vm.$route.query.customer_id)?_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"subheading"},[_vm._v("Filter:")]),_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){_vm.$router.push({ name: 'Tasks' });
        _vm.load();}}},[_vm._v(" Kunde ")])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 tasks",attrs:{"footer-props":{
      'items-per-page-options': [10, 50, 100]
    },"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.total,"items":_vm.tasks,"item-key":"id","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(props){return [_c('div',{staticClass:"editable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('input',{staticStyle:{"width":"20em"},attrs:{"type":"text"},domProps:{"value":props.item.name},on:{"blur":function (e) {
              props.item.name !== e.target.value
                ? _vm.update(Object.assign({}, props.item, {name: e.target.value}))
                : null;
            }}})])]}},{key:"item.description",fn:function(props){return [_c('div',{staticClass:"editable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('input',{staticStyle:{"width":"22em"},attrs:{"type":"text"},domProps:{"value":props.item.description},on:{"blur":function (e) {
              props.item.description !== e.target.value
                ? _vm.update(Object.assign({}, props.item, {description: e.target.value}))
                : null;
            }}})])]}},{key:"item.hourly_rate",fn:function(props){return [_c('div',{staticClass:"editable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('input',{staticStyle:{"width":"6em"},style:({ color: props.item.hourly_rate ? 'null' : 'grey' }),attrs:{"placeholder":"0","type":"number"},domProps:{"value":props.item.hourly_rate},on:{"blur":function (e) {
              props.item.hourly_rate !== e.target.value
                ? _vm.update(Object.assign({}, props.item, {hourly_rate: e.target.value}))
                : null;
            }}})])]}},{key:"item.duration",fn:function(props){return [(props.item.duration)?_c('router-link',{attrs:{"to":{
          name: 'Tracks',
          query: { task_id: props.item.id }
        }}},[_vm._v(_vm._s(_vm.formatSeconds(props.item.duration))+" ")]):_vm._e()]}},{key:"item.earnings",fn:function(props){return [(props.item.earnings)?_c('router-link',{attrs:{"to":{
          name: 'Tracks',
          query: { task_id: props.item.id }
        }}},[_vm._v(_vm._s(_vm.formatEarnings(props.item.earnings))+" € ")]):_vm._e()]}},{key:"item.total_duration",fn:function(props){return [(props.item.total_duration)?_c('router-link',{attrs:{"to":{
          name: 'Tracks',
          query: { task_id: props.item.id }
        }}},[_vm._v(_vm._s(_vm.formatSeconds(props.item.total_duration))+" € ")]):_vm._e()]}},{key:"item.total_earnings",fn:function(props){return [(props.item.total_earnings)?_c('router-link',{attrs:{"to":{
          name: 'Tracks',
          query: { task_id: props.item.id }
        }}},[_vm._v(_vm._s(_vm.formatEarnings(props.item.total_earnings))+" € ")]):_vm._e()]}},{key:"item.actions",fn:function(props){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTask(props.item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }